export function closeIcon(className, id) {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class = "${className || ""}" id = "${id || ""}">
            <path fill="currentColor" fill-rule="evenodd" d="M19 3.586L20.414 5 13.415 12 20.414 19 19 20.414 12 13.415 5 20.414 3.586 19 10.585 12 3.586 5 5 3.586 12 10.585z" opacity=".7"/>
        </svg>
    `;
}

export function fbIcon(className) {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class = "${className}">
            <path fill="#FFF" fillRule="evenodd" d="M7.379.001L16.309 0c2.674 0 3.644.278 4.622.801.977.523 1.745 1.29 2.268 2.268.523.978.801 1.948.801 4.623v8.616l-.005.61c-.041 2.22-.315 3.113-.796 4.013-.523.977-1.29 1.745-2.268 2.268-.978.523-1.948.801-4.623.801H7.692l-.61-.005c-2.22-.041-3.113-.315-4.013-.796-.977-.523-1.745-1.29-2.268-2.268-.502-.94-.778-1.87-.8-4.31L0 7.691C0 5.018.278 4.048.801 3.07c.523-.977 1.29-1.745 2.268-2.268C4.01.3 4.94.023 7.38.001zM16 5h-2.54c-2.32 0-3.388.99-3.456 2.881L10 8.08V10H8v3h2v6h3v-6h2.76l.24-3h-3V8.89c0-.597.4-.855.958-.887L14.08 8H16V5z"/>
        </svg>
    `;
}

export function backArrowIcon(className) {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class = "${className || ""}">
            <path fill="#1A1A1A" fill-rule="evenodd" d="M16 2.586L17.414 4 9.415 12 17.414 20 16 21.414 6.586 12z" opacity=".7"/>
        </svg>
    `;
}

export function twitterIcon(className) {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class = "${className}">
            <path fill="#FFF" fill-rule="evenodd" d="M7.379.001L16.309 0c2.674 0 3.644.278 4.622.801.977.523 1.745 1.29 2.268 2.268.523.978.801 1.948.801 4.623v8.616l-.005.61c-.041 2.22-.315 3.113-.796 4.013-.523.977-1.29 1.745-2.268 2.268-.978.523-1.948.801-4.623.801H7.692l-.61-.005c-2.22-.041-3.113-.315-4.013-.796-.977-.523-1.745-1.29-2.268-2.268-.502-.94-.778-1.87-.8-4.31L0 7.691C0 5.018.278 4.048.801 3.07c.523-.977 1.29-1.745 2.268-2.268C4.01.3 4.94.023 7.38.001zM15.58 6c-.995.004-1.935.458-2.557 1.235-.621.777-.858 1.793-.643 2.765-2.626-.124-5.077-1.352-6.75-3.38-.87 1.496-.433 3.41 1 4.38-.524-.023-1.035-.17-1.49-.43-.013 1.578 1.094 2.944 2.64 3.26-.485.136-.995.156-1.49.06.434 1.336 1.666 2.25 3.07 2.28-1.377 1.076-3.124 1.565-4.86 1.36 2.939 1.878 6.679 1.962 9.7.219 3.02-1.744 4.817-5.025 4.66-8.509.656-.476 1.222-1.065 1.67-1.74-.6.268-1.237.44-1.89.51.688-.406 1.204-1.05 1.45-1.81-.648.38-1.354.65-2.09.8-.63-.658-1.509-1.02-2.42-1z"/>
        </svg>
    `;
}

export function linkedinIcon(className) {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class = "${className}">
            <path fill="#FFF" fill-rule="evenodd" d="M7.379.001L16.309 0c2.674 0 3.644.278 4.622.801.977.523 1.745 1.29 2.268 2.268.523.978.801 1.948.801 4.623v8.616l-.005.61c-.041 2.22-.315 3.113-.796 4.013-.523.977-1.29 1.745-2.268 2.268-.978.523-1.948.801-4.623.801H7.692l-.61-.005c-2.22-.041-3.113-.315-4.013-.796-.977-.523-1.745-1.29-2.268-2.268-.502-.94-.778-1.87-.8-4.31L0 7.691C0 5.018.278 4.048.801 3.07c.523-.977 1.29-1.745 2.268-2.268C4.01.3 4.94.023 7.38.001zM8.91 8.69H6.05v9.21h2.86V8.69zm6.915-.206l-.255.006c-.589-.017-1.17.14-1.67.45-.327.214-.601.498-.804.83L13 9.94V8.69h-2.8v9.21h2.91v-4.57c-.01-.378.014-.756.07-1.13.05-.335.204-.646.44-.89.254-.203.566-.319.888-.332l.162.002c.374-.018.737.124 1 .39.222.268.361.594.4.94.025.244.04.488.047.733l.003.367v4.49H19v-5.06c.02-.752-.067-1.503-.26-2.23-.15-.614-.502-1.16-1-1.55-.573-.368-1.237-.567-1.915-.576zM7.48 4.1c-.442-.007-.868.167-1.18.48-.31.3-.486.71-.49 1.14-.005.444.172.87.49 1.18.308.314.73.49 1.17.49.385 0 .756-.135 1.05-.379l.12-.111.01.04c.644-.65.644-1.7 0-2.35-.303-.322-.728-.5-1.17-.49z"/>
        </svg>
    `;
}

export function emailIcon(className) {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class = "${className || ""}">
            <path fill="#1A1A1A" fill-rule="evenodd" d="M12 2c5.43 0 9.848 4.327 9.996 9.72l.001.08H22V13c0 2.21-1.79 4-4 4-1.195 0-2.268-.524-3-1.355-.732.83-1.805 1.355-3 1.355-2.142 0-3.891-1.684-3.995-3.8L8 13v-2c0-2.21 1.79-4 4-4 .729 0 1.413.195 2.001.536L14 7h2v6c0 1.105.895 2 2 2 1.054 0 1.918-.816 1.995-1.85L20 13v-1c0-4.418-3.582-8-8-8s-8 3.582-8 8 3.582 8 8 8c1.138 0 2.24-.237 3.253-.689l.301-.142.896-.445.89 1.791-.896.445C15.074 21.64 13.562 22 12 22 6.477 22 2 17.523 2 12S6.477 2 12 2zm0 7c-1.054 0-1.918.816-1.995 1.85L10 11v2c0 1.105.895 2 2 2 1.054 0 1.918-.816 1.995-1.85L14 13v-2c0-1.105-.895-2-2-2z" opacity=".7"/>
        </svg>
    `
}

export function emailSentIcon(className) {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" class = "${className || ""}">
            <path fill="#1A1A1A" fill-rule="evenodd" d="M43.688 25.189l3.123 2.499-10.645 13.306L29.172 34 32 31.172l3.834 3.832 7.854-9.815zM44 8v14h-4v-3.764l-16 8-16-8V34c0 1.026.772 1.871 1.767 1.987L10 36h16v4H10c-3.195 0-5.807-2.498-5.99-5.647L4 34V8h40zm-4 4H8v1.764l16 7.998 16-8V12z"/>
        </svg>
    `
}

export function emailSolidIcon(className) {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class = "${className || ""}">
            <path fill="#FFF" fill-rule="evenodd" d="M22 8.118V17c0 1.598-1.249 2.904-2.824 2.995L19 20H5c-1.598 0-2.904-1.249-2.995-2.824L2 17V8.118l10 5 10-5zM22 4v1.881l-10 5-10-5V4h20z"/>
        </svg>
    `;
}

export function passwordIcon(className) {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class = "${className || ""}">
            <path fill="#1A1A1A" fill-rule="evenodd" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zm0 4c1.105 0 2 .895 2 2 0 .817-.49 1.52-1.191 1.83L14 16h-4l1.191-4.17C10.49 11.52 10 10.817 10 10c0-1.105.895-2 2-2z" opacity=".7"/>
        </svg>
    `
};

export function visibilityPasswordIcon(className) {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class = "${className || ""}">
            <path fill="#1A1A1A" fill-rule="evenodd" d="M4 2.586l2.311 2.31 1.446 1.447L17.794 16.38l1.416 1.415L21.414 20 20 21.414l-2.313-2.312C15.966 20.355 14.071 21 12 21c-4.335 0-7.912-2.784-10.705-8.181L.882 12l.223-.447C2.209 9.346 3.44 7.566 4.793 6.208L2.586 4 4 2.586zm2.213 5.041c-.979.973-1.9 2.218-2.763 3.766l-.193.354-.134.253.134.253c2.313 4.314 5.045 6.512 8.217 6.729l.272.014L12 19c1.514 0 2.926-.451 4.246-1.34l-1.489-1.489c-1.94 1.28-4.588 1.069-6.292-.635-1.704-1.704-1.916-4.353-.636-6.293zM11.999 3c4.335 0 7.914 2.784 10.707 8.18l.189.373.224.447-.224.448c-.733 1.466-1.523 2.743-2.366 3.837l-1.439-1.436c.503-.672.991-1.401 1.46-2.241l.194-.355.131-.253-.131-.253c-2.314-4.314-5.046-6.511-8.217-6.73l-.273-.013L11.998 5c-.818 0-1.607.13-2.37.387L8.087 3.844C9.313 3.283 10.617 3 11.998 3zm-2.696 7.717c-.525 1.114-.341 2.488.576 3.404.916.917 2.29 1.101 3.404.576zm2.01-3.647c1.5-.206 3.075.246 4.222 1.394 1.148 1.148 1.601 2.723 1.395 4.223z" opacity=".7"/>
        </svg>
    `;
};

export function profileIcon(className) {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class = "${className || ""}">
            <path fill="#1A1A1A" fill-rule="evenodd" d="M12 2c5.523 0 10 4.477 10 10 0 2.407-.859 4.678-2.377 6.463l.061.067-.739.654-.009.01c-.202.194-.411.38-.627.556l-.121.106C16.438 21.23 14.279 22 12 22c-2.28 0-4.44-.771-6.183-2.148l-.004.004-.104-.09c-.148-.121-.293-.246-.435-.375l-.22-.206-.739-.655.063-.066C2.858 16.678 2 14.407 2 12 2 6.477 6.477 2 12 2zm-.001 16c-1.87 0-3.32.24-4.405.67C8.884 19.53 10.404 20 12 20c1.595 0 3.115-.471 4.406-1.328C15.32 18.24 13.87 18 11.999 18zM12 4c-4.418 0-8 3.582-8 8 0 1.956.708 3.797 1.96 5.236C7.425 16.43 9.423 16 11.999 16c2.577 0 4.575.43 6.04 1.235C19.293 15.798 20 13.956 20 12c0-4.418-3.582-8-8-8zm.006 2C14.459 6 16 7.846 16 10.494 16 13.143 14.459 15 12.006 15 9.542 15 8 13.143 8 10.494 8 7.846 9.542 6 12.006 6zm0 2C10.323 8 10.02 9.453 10 10.392l-.001.102c0 .754.195 2.506 2.006 2.506 1.736 0 1.978-1.63 1.993-2.422v-.168l-.008-.188C13.94 9.39 13.613 8 12.006 8z" opacity=".7"/>
        </svg>
    `;
}

export function wideMenuIcon(className) {
    return `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class = "${className || ""}">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21 17V19H3V17H21ZM21 11V13H3V11H21ZM21 5V7H3V5H21Z" fill="rgba(255, 255, 255, 0.7)"/>
        </svg>
    `;
}
