import { BaseComponent } from '../../atoms/BaseComponent';
import { wideMenuIcon } from '../../atoms/Icons';

class MobileMenuForm extends BaseComponent {
    changeState() {
        this.state.menuOpened = !this.state.menuOpened;
        if (this.state.menuOpened) {
            this.openMenu();
        } else {
            this.closeMenu();
        }
    }

    openMenu() {
        document.querySelector('.mobile-menu-list').style.display = 'block';
    }

    closeMenu() {
        document.querySelector('.mobile-menu-list').style.display = 'none';
    }

    connectedCallback() {
        super.connectedCallback();
        this.state = {
            menuOpened: false
        };
        document.querySelector('.mobile-menu-list').style.display = 'none';

        document.querySelector('.mobile-menu-button')
            .addEventListener('click', () => {
                this.changeState();
            });

        document.querySelector('.mobile-menu-list-item.cancel')
            .addEventListener('click', () => {
                this.changeState();
            })

        const arr = Array.from(document.querySelectorAll('.mobile-menu-list-item:not(.cancel)'));
        arr.forEach((it) => {
            it.addEventListener('click', () => {
                this.changeState()
            })
        })
    }

    render() {
        this.innerHTML = `
            <div class = 'c-mobile-menu'>
                <button class = "mobile-menu-button">
                    ${wideMenuIcon()}
                </button>
                <div class = "mobile-menu-list">
                    <a class = "mobile-menu-list-item" href = "/#block-platform">
                        Platform
                    </a>

                    <a class = "mobile-menu-list-item" href = "/#block-ui">
                        How it works
                    </a>

                    <a class = "mobile-menu-list-item" href = "/#block-mission">
                        Mission
                    </a>

                    <div class = "mobile-menu-list-item cancel">
                        Cancel
                    </div>
                </div>
            </div>
        `
    }
}

customElements.define('mobile-menu', MobileMenuForm);


class ServiceMobileMenuForm extends BaseComponent {
    changeState() {
        this.state.menuOpened = !this.state.menuOpened;
        if (this.state.menuOpened) {
            this.openMenu();
        } else {
            this.closeMenu();
        }
    }

    openMenu() {
        document.querySelector('.mobile-menu-list').style.display = 'block';
    }

    closeMenu() {
        document.querySelector('.mobile-menu-list').style.display = 'none';
    }

    connectedCallback() {
        super.connectedCallback();
        this.state = {
            menuOpened: false
        };
        document.querySelector('.mobile-menu-list').style.display = 'none';

        document.querySelector('.mobile-menu-button')
            .addEventListener('click', () => {
                this.changeState();
            });

        document.querySelector('.mobile-menu-list-item.cancel')
            .addEventListener('click', () => {
                this.changeState();
            })

        const arr = Array.from(document.querySelectorAll('.mobile-menu-list-item:not(.cancel)'));
        arr.forEach((it) => {
            it.addEventListener('click', () => {
                this.changeState()
            })
        })
    }

    render() {
        this.innerHTML = `
            <div class = 'c-mobile-menu'>
                <button class = "mobile-menu-button">
                    ${wideMenuIcon()}
                </button>
                <div class = "mobile-menu-list mobile-menu-list__service">
                    <a class = "mobile-menu-list-item" href = "/about.html">
                        About Coach Camp
                    </a>

                    <a class = "mobile-menu-list-item" href = "/charity.html">
                        Charity
                    </a>

                    <a class = "mobile-menu-list-item" href = "/terms.html">
                        Terms of Use
                    </a>

                    <a class = "mobile-menu-list-item" href = "/privacy.html">
                        Privacy & Cookies Policy
                    </a>

                    <div class = "mobile-menu-list-item cancel">
                        Cancel
                    </div>
                </div>
            </div>
        `
    }
}

customElements.define('service-mobile-menu', ServiceMobileMenuForm);
