import '@webcomponents/custom-elements';

if (typeof HTMLElement === 'object') {
    const _Element = () => {};
    _Element.prototype = HTMLElement.prototype;
    HTMLElement = _Element;
}

export default function HTMLElementBase() {
    const newTarget = this.__proto__.constructor;
    if (typeof Reflect === "undefined") {
        /* $FlowFixMe */
        return HTMLElement.apply(Object.create(newTarget.prototype), []);
    } else {
        /* $FlowFixMe */
        return Reflect.construct(HTMLElement, [], newTarget);
    }
}

Object.setPrototypeOf(HTMLElementBase, HTMLElement);
Object.setPrototypeOf(HTMLElementBase.prototype, HTMLElement.prototype);

(function () {
    if (typeof window.CustomEvent === 'function') return false;

    function CustomEvent(event, params) {
        const args = params || { bubbles: false, cancelable: false, detail: undefined };
        const evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, args.bubbles, args.cancelable, args.detail);
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;

    return true;
}());

export class BaseComponent extends HTMLElementBase {
    template(args) {
        return args ? '' : '';
    }

    render() {
        console.log('render items');
        this.innerHTML = this.template(this.getAttribute('args'));
        this.initEvents();
    }

    initEvents() {}

    connectedCallback() {
        this.render();
    }
}
